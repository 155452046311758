<template>
  <div >
    <b-card >
      <b-row  >
        <b-col sm="12" md="3" class="text-center">
          <img 
            width="25%"
            style="border-radius:50%;"
            src="@/assets/Abdulla_Avloniy1.jpg"
          />

          <b-card-text>
            <p class="school32 text-center">Абдулла Авлоний<br />(1878 -1917 )</p></b-card-text
          >
        </b-col>
        <b-col sm="12" md="6">
           <b-card-text>
          <p class="TextBlack17">
            Машҳур ўзбек маърифатпарвари, шоири ва жамоат арбоби Абдулла Авлоний
            1878 йил 12 июлда Тошкентда ҳунарманд оиласида туғилган. XX аср
            бошларида Туркистон маданий ҳаётида юз берган энг муҳим
            ўзгаришлардан бири мактаб-ўқув ишларида ўзгариш бўлди. Авлоний бу
            даврда жадидчилик ҳаракатига қўшилиб, Тошкентдаги жадидларнинг фаол
            иштирокчиларидан бири бўлиб танилди. Авлоний 1904 йилда Мирободда,
            сўнгроқ Дегрезликда (1903—14) худди шундай янги усулда, янгича
            мактаб очиб, дарс берди ва дарсликлар ёзди. 1909 йилда мактаб-маориф
            ишларига ёрдам берувчи «Жамияти хайрия» очиб, етим болаларни ўқитди.
            «Адабиёт ёхуд миллий шеърлар» номли тўрт қисмдан иборат шеърий
            тўпламининг биринчи жузъини нашр қилдирди. Жадидчилик ҳаракати
            раҳбарларидан бири бўлган Авлоний 1904 йилда Тошкентнинг Миробод
            маҳалласида жадид мактабини, 1909 йилда «Жамияти хайрия» уюшмасини
            ташкил этади. 1905-1917 йилларда матбуотдаги қизғин фаолияти, кўплаб
            публитсистик мақолалари билан «бир ёқдан миллий ислоҳот учун мафкура
            ҳозирлағон, иккинчи ёқдан эл орасига ўзгариш тухмини сочғон» ҳам
            Абдулла Авлоний бўлди. Авлоний ўзи нашр этган «Шуҳрат» (1907) номли
            газетаси фаолиятини шундай баҳолаган эди.У Туркистонда биринчилардан
            бўлиб мактабга география, кимё, хандаса, физика фанлари киритилишига
            таъсир кўрсатди, таълимни реал турмуш билан боғлашга интилди, бир
            дарс билан бошқаси ўртасида муайян танаффусни, бир синфдан
            иккинчисига ўтишдаги имтиҳонни жорий этди, таълим тизимининг дунёвий
            йўналишини кучайтиршига алоҳида эътибор берди. Унинг «Биринчи
            муаллим» («Муаллими аввал», 1909), «Иккинчи муаллим» («Муаллими
            соний», 1912) номли дарсликлари, «Туркий гулистон ёхуд ахлоқ»
            (1913), «Гулистони мактаб» (1917), «Адабиёт ёхуд миллий шеърлар»
            (1909-1916, 6 қисм) каби қўлланмалари мактабларда ўқитилган. Авлоний
            «Турон» (1917) газетасига «Яшасин халқ жумҳурияти» деган шиорни
            танлади, «Турон» номли театр тўгарагини ташкил этиб, иқтидорли
            ёшларни тўплади, айрим ролларни ижро этишда ўзи ҳам қатнашди. Шоир
            Афғонистонда элчи бўлди, турли ўқув юртида дарс берди. 1916 йилги
            мардикорлик воқеаларининг, сўнг инқилобий талотумлару миллий-озодлик
            курашларининг гувоҳи бўлди Авлоний мактаби гуманистик ва эркин
            тарбия асосига қурилган, дунёвий ва илғор илм-фанни болаларга
            ўргатишни ўз олдига асосий вазифа қилиб қўйган, ёшларни мамлакатнинг
            ижтимоий-сиёсий ҳаётига аралаша олиш қобилиятига эга бўлишини
            таъминлайдиган ҳақиқий халқ мактаби бўлди. Адиб бу мактаблар учун
            дарсликлар тузди. Унинг аввалги синф шогирдлари учун «Биринчи
            муаллим»и (1911) Октябр ўзгаришигача 4 марта, «Алифбедан сўнгги ўқув
            китоби» — «Иккинчи муаллим» (1912) 3 марта кайта нашр этилган.
            Ахлоқий-дидактик мазмундаги «Туркий Гулистон ёхуд ахлоқ» дарслиги
            (1913) XX аср бошлари ижтимоий-педагогик фикр тараққиётида алоҳида
            ўрин эгаллади. Унда тарбия ва ахлоқ масалалари биринчи маротаба XX
            асрнинг талаб ва эҳтиёжлари нуқтаи назаридан таҳлил қилинган.
            Авлоний 1917 йил феврал инқилобини хурсандчилик билан кутиб олди
            («Қутулдик», «Ётма» шеърлари). Октябрга бағишлаб «Ҳуррият марши»
            (1919), «Ишчилар қулоғига» каби шеърлар ёзди, янги социалистик
            тузумни улуғлади. Лекин кўп ўтмай, рус совет тузуми эски чор
            тузумининг оддингидан баттарроқ шакли эканлигини, совет сиёсати
            риёкорлик асосига қурилганлигини англай бошлади. Жумладан,
            тантанавор ваъда қилинган эркинликнинг берилмаганлиги шоир ижодида
            ғамгин-тушкун оҳангларнинг пайдо бўлишига олиб келди («Ҳафталик
            соатда» 1919). Шуларга қарамасдан Авлоний турли мавзуларда шеърлар
            ёзди. 1919—20 йилги Афғонистон сафарига доир «Афғон саёҳати»
            кундаликлари эса мамлакатимизнинг ён қўшнимиз билан ўзаро дўстлик,
            тотувлик алоқаларининг ўрнатилиши тарихини ўрганишда муҳим аҳамиятга
            эга. «Адвокатлик осонми?» деган асарида бир қатор кўкнори ва
            қиморбозлар образини яратиб, маънавий турмушнинг тубан бир ҳолга
            келиб қолганлини кўрсатди. Монархияга қарши кураш, байроғи остида
            кечган 1910 йилги Португалия инқилоби, 1909 йили Туркияда юз берган
            «Ёш турклар» инқилоби («Икки севги») ҳақида ёзиб, адабиётимизда
            мавзу ва ғоялар кўламини кенгайтирди. «Биз ва Сиз»да эса XX аср
            бошидаги Туркистоннинг эскилик ва янгилик борасидаги курашини аниқ
            тақдирлар мисолида ёритиб берди. Авлоний асосий ижодий фаолиятининг
            энг сермаҳсул йиллари 1917 йилдаги Октябр тўнтаришига кадар бўлган
            даврга тўғри келади.
          </p>
        </b-card-text>
        </b-col>
        <b-col sm="12" md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
